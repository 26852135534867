:root {
    --primary-color: rgba(13, 110, 139, 0.75);
    --overlay-color: rgba(24, 39, 51, 0.85);
    --menu-speed: 0.75s;
}

ul {
    list-style: none;
}

.navbar {
    /* height: 120px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
}

.logo {
    font-size: 40px;
    font-weight: 800;
}

.nav-button {
    border: 1px solid #fff;
    border-radius: 13px;
}

.nav-button ul {
    list-style: none;
    display: flex;
}

.nav-button ul li {
    padding: 10px 18px;
}

.nav-button ul li a {
    color: #fff;
}

.btn-primary {
    background-color: #fff;
    padding: 10px 18px;
    border-radius: 20px;
    font-size: 16px;
}



.menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
}

.menu-wrap .toggler {
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
}

.menu-wrap .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: var(--primary-color);
    z-index: 1;
}

.menu-wrap .hamburger>div {
    width: 100%;
    height: 2px;
    background: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
}

.menu-wrap .hamburger>div::before,
.menu-wrap .hamburger>div::after {
    content: '';
    position: absolute;
    top: -10px;
    width: 100%;
    height: 2.2px;
    background: inherit;
    z-index: 1;
}

.menu-wrap .hamburger>div::after {
    top: 10px;
}

.menu-wrap .toggler:checked+.hamburger>div {
    transform: rotate(135deg);
}

.menu-wrap .toggler:checked+.hamburger>div:before,
.menu-wrap .toggler:checked+.hamburger>div:after {
    top: 0;
    transform: rotate(90deg);
}

.menu-wrap .toggler:checked:hover+.hamburger>div {
    transform: rotate(225deg);
}

.menu-wrap .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.menu-wrap .toggler:checked~.menu {
    visibility: visible;
}

.menu-wrap .menu>div {
    height: 200vh;
    width: 200vw;
    transform: scale(0);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--overlay-color);
    border-radius: 50%;
    flex: none;
    transition: all 0.4s ease;
}

.menu-wrap .toggler:checked~.menu>div {
    transform: scale(1);
    transition-duration: var(--menu-speed);
}

.menu-wrap .menu>div>div {
    text-align: center;
    max-width: 90vw;
    max-height: 100vh;
    opacity: 0;
    transition: opacity 0.4s ease;
}

.menu-wrap .toggler:checked~.menu>div>div {
    opacity: 1;
    transition: opacity 0.4s ease 0.4s;
}

.menu-wrap .menu>div>div>ul {
    list-style: none;
}

.menu-wrap .menu>div>div>ul>li {
    /* font-size: 25px; */
    padding: 1rem;
    color: #fff;
    /* font-size: 1.5rem; */
}

.menu-wrap .menu>div>div>ul>li>a {
    font-size: 1.5rem;
    color: inherit;
    text-decoration: none;
    transition: color 0.4s ease;
}


@media (max-width: 875px) {
    .nav-button {
        display: none;
    }
    .menu-wrap {
        display: inline;
    }
    .call-to-action-nav{
        display: none;
    }
    .navbar{
        justify-content: center;
    }
}

@media (max-width: 633px) {
    .logo{
        font-size: 30px;
    }
    .btn-primary{
        font-size: 12px;
    }
}